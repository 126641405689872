import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="sectionBL">
          <h1>Bienvenid@ a Top Food BCN</h1>
        </div>
       
      </header>
    </div>
  );
}

export default App;
